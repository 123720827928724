<template>
  <div>
    <h1>Oops !</h1>
    <h3>The site you're looking for is not here.</h3>
    <router-link :to="{ name: 'home' }">Back to homepage</router-link>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
